
.relationMenuItem {
  padding: 0;
}

.today {
  position: relative;
}

.today::before {
  content: "";
  position: absolute;
  top: 10px;
  left: 0;
  width: 100%;
  height: 1px;
  background-color: var(--color-user-red);
}