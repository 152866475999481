.side {
  display: block;
  min-width: 500px;
  width: 43%;
  max-width: 1200px;
  border-left: var(--border);
}

.settings {
  min-width: 400px;
  width: 20vw;
}

.control.control {
  background: var(--color-background-tertiary);
}

.flow {
  width: 400px;
  height: 200px;
  border-radius: var(--border-radius-large);
  overflow: hidden;
}