.main {
  background: var(--color-background);
  height: 100%;
  border-left: var(--border-tertiary);
  /* Colored section hack... */
  z-index: 0;
  display: block;
}

.main:not(:last-child) {
  border-right: var(--border-tertiary);
}

.nav {
  min-height: 100%;
  display: block;
  width: 260px;
  padding: 30px 20px 10px;
}

.heading.heading {
  color: inherit;
}