.nav {
  border-right: var(--border-secondary);
  min-height: 100%;
  display: block;
  width: 260px;
  padding: 30px 16px 10px;
}

.viewPane.viewPane {
  border: none;
  border-radius: 0;
  padding: 0;
  box-shadow: none;
}