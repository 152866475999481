.list {
  min-height: 200px;
  padding: 6px 8px;
  height: 100%;
}

.sheet.sheet {
  padding: 0;
  min-height: 100px;
  display: flex;
  flex-direction: column;
  align-items: stretch;
}

.header {
  padding: 15px;
  padding-bottom: 0;
}


.title {
  color: var(--color-text);
}

.grouped {
  height: 100%;
}

.padded {
  padding: 0 8px;
}

.columnContainer {
  overflow: hidden;
  padding: 12px;
}

.column {
  padding: 6px;
  background-color: var(--color-background-secondary);
  min-width: 250px;
  max-width: 250px;
  min-height: 300px;
  border-radius: var(--border-radius-small);
  height: 100%;
}

.tabbedButtons {
  padding: 6px;
  background-color: var(--color-background-tertiary);
  border: var(--border-secondary);
  border-radius: var(--border-radius-medium);
}

.tabbedButton {
  border-radius: calc(var(--border-radius-medium) - 2px);
  padding: 3px 4px;
}
.tabbedButton:not(.active) {
  border-color: transparent;
}
.tabbedButton.active {
  background-color: var(--color-background);
  border-color: var(--color-border-secondary);
  box-shadow: var(--shadow-control);
}