.popover {
  min-width: 350px;
}

.listItem {
  transition: box-shadow 50ms ease-in-out;
  border: var(--border-secondary);
  border-radius: var(--border-radius-medium);
  box-shadow: var(--shadow-elevation-low);
  background-color: var(--color-background);
}