.authCard {
  width: 100%;
  height: auto;
  min-height: 400px;
  max-width: 400px;
  margin: 0 auto;
  padding: 30px;
}

.logo {
  color: var(--color-text-heading);
}

.logoCenter {
  padding-right: 20px;
  width: 100%;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
}