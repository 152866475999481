.banner {
  height: 28px;
}

/* Hacky but whelp */
.banner + * {
  height: calc(100% - 28px) !important;
}


.link {
  text-decoration: underline;
}

