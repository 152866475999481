.nav {
  min-height: 100%;
  display: block;
  width: calc(min(40%, 600px));
  padding: 30px 20px;
}

.noPane.noPane {
  background: none;
  box-shadow: none;
  border: none;
  border-radius: 0;
  flex: 1 1 auto;
  height: auto;
  padding-bottom: 60px;
}

.paneRight {
  padding-top: 20px;
  width: 100%;
  border-radius: 0;
  box-shadow: var(--shadow-elevation-high);
  background: rgba(var(--color-rgb-background), 0.84);
  backdrop-filter: blur(9.6px);
  border-left: 1px solid var(--color-border-secondary);
  overflow: visible;
}

.paneRight > * {
  overflow: visible;
}

.empty {
  height: 100%;
  background-color: var(--color-background-tertiary);
  border-left: 1px solid var(--color-border-secondary);
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.fitHeight {
  height: 100%;
}

.fitContent.fitContent {
  height: fit-content;
}

.inboxItem {
  padding-top: 10px;
  padding-bottom: 10px;
}

.noteBody {
  display: inline;
  color: var(--color-text-secondary);
}

.relative {
  position: relative;
}

.unread {
  right: calc(100% + 4px);
  left: auto;
  top: 4px;
}

.placeholder {
  color: var(--color-text-placeholder);
}