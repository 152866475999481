.control {
  background-color: var(--color-background-tertiary);
}

.tasksSection {
  min-height: 200px;
}

.modal {
  width: 80vw;
  /* height: 80vh; */
  max-width: 1400px;
  max-height: 1000px;
}

.left {
  flex-basis: 40%;
  background-color: var(--color-background);
}

.right {
  flex-basis: 60%;
}

.spaceBottom {
  margin-bottom: 20px;
}

.overflow {
  height: 100%;
  width: 100%;
  max-height: 100%;
  overflow: auto;
}