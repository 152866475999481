/* 2x strength */
.heading.heading {
  font: var(--font-heading-large-bold);
  color: var(--color-text-heading);
}

.secondary {
  color: var(--color-text-secondary);
}

.propertyValue.propertyValue {
  background: var(--color-background);
  border: var(--border);
  min-width: 200px;
  padding-left: 6px;
  padding-right: 9px;
}

.propertyValue:hover {
  background: var(--color-subtle-hover);
}

.propertyValue:active {
  background: var(--color-subtle-active);
}

.roundedNew {
  border-radius: 100px;
  padding-left: 16px;
  padding-right: 16px;
}
