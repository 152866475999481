.flow :global(.react-flow__renderer) {
  height: calc(100vh - 40vh) !important;
}

.flow:not(.editing) :global(.react-flow__pane)  {
  cursor: default;
}

.text {
  line-height: 1.2em;
}

.handle.hidden,
.handle.hidden :global(.flow__handle) {
  opacity: 0;
  pointer-events: none;
  cursor: default;
}

.largeIcon {
  font-size: 28px;
  font-weight: bold;
  color: inherit;
  border-radius: 16px;
  line-height: 60px;
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: transform 0.2s ease-out, border-radius 0.2s var(--snappy-bezier), opacity 0.3s ease-in-out;
}


.white {
  color: white;
}

.team {
  overflow: visible;
  cursor: pointer;
  min-width: 120px;
  max-width: 120px;
  display: flex;
  flex-direction: column;
  align-items: center;
  text-align: center;
  row-gap: 10px;
}

.team .label {
  max-width: 100%;
  transition: transform 0.2s ease-out, opacity 0.3s var(--snappy-bezier);
}

.team:hover .largeIcon {
  transform: scale(1.1);
  border-radius: 20px;
}

.team:active .largeIcon {
  transform: scale(2);
  opacity: 0.2;
}

.team:active .label {
  transform: translateY(30px);
  opacity: 0;
}

.team.faint {
  opacity: 0.2;
}