
.paneContainer {
  background: var(--color-background-tertiary);
  overflow: auto;
  min-height: 200px;
}

.paneActions {
  padding: 10px 12px;
}

.updateBody {
  color: var(--color-text);
}