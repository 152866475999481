
.container {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  overflow: auto;
  color: white;
  display: flex;
  flex-direction: column;
}

.container::after {
  content: "";
  position: fixed;
  z-index: -1;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  transition: all 0.5s var(--snappy-bezier);
  background: rgba(7  7  16 / 0.8);
  backdrop-filter: blur(16px);
  opacity: 1;
}

.bottomBar {
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 20px;
  transition: all 0.3s var(--snappy-bezier);
  transform: translateY(0);
  opacity: 1;
}

.workspaceBar, .spacesBar {
  box-sizing: border-box;
  background: rgba(7 7 9  / 0.2);
  border: 1px solid rgba(248, 248, 255, 0.05);
  box-shadow: inset 0px 1px 0px rgba(255, 255, 255, 0.1);
  color: white;
  transition: all 0.3s var(--snappy-bezier);
  transform: translateY(0);
  opacity: 1;
}

.icon.largeIcon {
  box-shadow: 0 0px 0px 2px rgba(0, 0, 0, 0.08);
  transition: all 0.3s var(--snappy-bezier);
}

.icon:not(.largeIcon) {
  box-shadow: 0 0px 0px 1px rgba(0, 0, 0, 0.1);
  transition: all 0.3s var(--snappy-bezier);
}

.icon.selected {
  outline: 4px solid var(--color-primary);
}

.addIcon {
  width: 100%;
  height: 100%;
  border: 1px solid rgba(255, 255, 255, 0.1);
  background-color: transparent;
  border-radius: inherit;
  transition: all 0.3s var(--snappy-bezier);
}

.addIcon:hover {
  background-color: rgba(255, 255, 255, 0.3);
}

.addIcon:active {
  transform: scale(0.9)
}

.teams .addIcon {
  margin-top: 10px;
}

.addIcon > * {
  width: 100%;
  height: 100%;
  background-color: transparent !important;
}

.button:hover .icon {
  box-shadow: 0 0 8px 1px rgba(0, 0, 0, 0.2);
}

.mediumIcon {
  border-radius: 12px;
}

.mediumIcon.addIcon {
  width: 44px;
}

.largeIcon {
  font-size: 28px;
  font-weight: bold;
  color: inherit;
  border-radius: 16px;
  line-height: 60px;
  width: 60px;
  min-width: 60px;
  height: 60px;
  display: flex;
  align-items: center;
  justify-content: center;
}


.teamStack.dark .icon,
.teamStack.dark .button,
.teamStack.dark .addIcon *,
.teamStack.dark .text {
  color: var(--color-text);
}

.icon,
.button,
.addIcon *,
.text {
  color: white;
}

.button.team {
  padding-top: 12px;
  padding-bottom: 12px;
  padding-left: 0;
  padding-right: 0;
}

.fixedTeams {
  padding: 10px;
}

.fixedTeams .tabBar {
  transition: all 0.2s var(--snappy-bezier);
  transform: translateY(0);
  opacity: 1;
}

.teams .team .icon,
.fixedTeams .button {
  transition: opacity 0.3s var(--snappy-bezier), transform 0.2s var(--snappy-bezier);
  transform: scale(1);
  opacity: 1;
}

.teams {
  height: 100vh;
  transition: all 0.3s var(--snappy-bezier);
  opacity: 1;
}

.teamStack:not(:hover) .addIcon {
  opacity: 0;
}

.teamStack .team {
  width: 100%;
}

.team .text {
  width: 150px;
  text-align: center;
  text-wrap: wrap;
  transition: all 0.3s var(--snappy-bezier);
}

.team.small .text {
  width: 100px;
}

.team:hover {
  background-color: rgba(255, 255, 255, 0.1);
}

.team:active {
  transform: scale(0.95);
  background-color: rgba(255, 255, 255, 0.2);
  transition: none;
}

.tabBar {
  background: #383839;
  border-radius: 8px;
  border: none;
  margin-top: 20px;
}

/* Lol gross.. */
.tabBar > * > *:not([aria-selected="true"]) p {
  color: white;
}

/*
* Animations
*/

.animation:not(.in).container::after {
  background: rgba(7, 7, 8, 0);
  backdrop-filter: blur(0px);
}

.animation:not(.in) .teams {
  opacity: 0;
}

.animation:not(.in) .icon.selected {
  transform: scale(3);
}

.animation:not(.in) .fixedTeams .button {
  opacity: 0;
}

.animation:not(.in) .fixedTeams .tabBar,
.animation:not(.in) .workspaceBar {
  transform: translateY(-100%);
  opacity: 0;
}

.animation:not(.in) .bottomBar {
  transform: translateY(100%);
  opacity: 0;
}

