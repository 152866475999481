.header {
  padding: 10px 16px;
}

.bordered {
  border-bottom: var(--border);
}

.title {
  font: var(--font-heading-bold);
}
