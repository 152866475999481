.container {
  height: auto;
  width: auto;
  min-width: 100px;
  transition: min-width 200ms ease;
}

.container.updating,
.container.open {
  min-width: 350px;
}

.container.open {
  min-height: 400px;
}

.footer.open {
  border-top: var(--border);
}

.red {
  color: var(--color-user-red);
}

.hidden {
  opacity: 0;
  pointer-events: none;
  transform: translateY(100%);
}

.allChanges {
  max-height: 50vh;
  overflow: auto;
}

.offscreen.offscreen {
  right: -100%;
}