.header {
  padding: 20px;
  /* background-color: var(--color-background-secondary); */
  width: 100%;
}

.side {
  display: block;
  width: 300px;
  padding: 20px;
}

.main {
  padding: 6px;
  width: 100%;
}

.flow {
  background-color: var(--color-background);
  border-radius: var(--border-radius-large);
  overflow: hidden;
}

.menuGroup:empty {
  display: none;
}

.full {
  min-height: 500px;
  height: 100%;
}