
.main {
  background-color: rgba(var(--color-rgb-background), 0.9);
  padding-top: 0px;
}

.nav {
  padding: 30px 20px 10px;
  border-right: var(--border-secondary);
  min-height: 100%;
  display: block;
  width: 260px;
}

.teamName {
  margin-bottom: 10px;
}

.menuGroup {
  margin-bottom: 10px;
}

.root {
  margin-top: 10px;
}

.breadcrumbIcon {
  color: var(--color-icon);
}