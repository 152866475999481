.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  opacity: 0.1;
  z-index: 1;
}
.container {
  position: fixed;
  display: block;
  top: calc(min(20%, 200px));
  left: 50%;
  transform: translate(-50%, 0%);
  max-width: 640px;
  z-index: 1;
  width: 100%;

  padding: 0;
  overflow: hidden;
  background: linear-gradient(0deg, rgba(var(--color-rgb-background), 0.45) 0%, rgba(var(--color-rgb-background), 0.85) 40%);
  backdrop-filter: blur(6.8px);
  border: 1px solid rgba(var(--color-rgb-background), 0.3);
  box-shadow: var(--shadow-popover);
  border-radius: var(--border-radius-large);
  transition: top 350ms ease;
}

.container.peaking {
  top: calc(100vh);
}

.header {
  padding: 8px 16px 0 16px;
  margin-bottom: -6px;
}

.divider {
  border-bottom: var(--border-secondary);
  mix-blend-mode: multiply;
  width: 100%;
  height: 1px;
  margin: 5px 0;
}

.inputContainer {
  border-bottom: var(--border-secondary);
  border-radius: 0;
  padding: 8px;
}

.prefix {
  font: var(--font-text-large);
  color: var(--color-text);
  padding-left: 8px;
}

.input {
  font: var(--font-text-large);
  color: var(--color-text);
  background: none;
  padding: 7px 8px;
  border: none;
  width: 100%;
  outline: none;
  caret-color: var(--color-text-heading);
  margin: 0;
  flex: 1 1 auto;
}

.input.disabled {
  pointer-events: none;
}

.input::placeholder {
  color: var(--color-text-placeholder);
}

.item {
  padding: 9px 16px;
  border-radius: 0;
}

.groupItems {
  margin: 0;
}

.itemContainer:global([aria-selected="true"]) .item {
  background: var(--color-subtle-hover);
}

.itemContainer:global(:not([aria-selected="true"])) .item {
  background: none;
}

.itemContainer:active .item {
  background: var(--color-subtle-active);
}

.container :global([cmdk-list]) {
  min-height: 0px;
  max-height: calc(min(60vh, 700px));
  overflow-y: auto;
  overflow-x: hidden;
  overscroll-behavior: contain;
  transition: 100ms ease;
  transition-property: height;
}

.container :global([cmdk-empty]) {
  font: var(--font-text);
  display: flex;
  align-items: left;
  justify-content: flex-start;
  padding: 11px 16px;
  white-space: pre-wrap;
  color: var(--color-text-placeholder);
}

.group {
  padding-left: 0;
  margin-top: 10px;
}

.group + .group {
  margin-top: 20px;
}

.groupLabel {
  padding-left: 10px;
  padding-right: 6px;
}


.dismiss {
  color: var(--color-text-placeholder);
  opacity: 0.4;
}