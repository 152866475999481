.nav {
  padding: 20px;
  background-color: var(--color-background);
  width: 40%;
}

.main {
  padding: 20px;
}

.control {
  background-color: var(--color-background-tertiary);
}

.fileInput {
  background-color: var(--color-background-tertiary);
  padding: 6px 10px;
  border-radius: var(--border-radius-small);
}

.half {
  width: 50%;
}