.select {
  padding: 20px 0;
}

.icon {
  border-radius: 4px;
  overflow: hidden;
}

.spaceIcon {
  font-size: 34px;
  width: 34px;
  min-width: 34px;
  height: 34px;
  line-height: 34px;
}

.spaceIcon > * {
  height: 100%;
}

.spaceContainer {
  padding: 10px 0 6px 0;
}

.spaceButton.spaceButton {
  width: 100%;
  margin-left: -6px;
  margin-right: -6px;
  width: calc(100% + 12px);
}

.activeLabel.activeLabel {
  font: var(--font-text-large-bold);
  color: var(--color-text-heading);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}