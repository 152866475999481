.select {
  padding: 20px 0;
}

.icon {
  border-radius: 4px;
  overflow: hidden;
}

.workspaceContainer {
  padding: 10px 0 6px 0;
}

.workspaceButton {
  width: 100%;
}

.activeLabel.activeLabel {
  font: var(--font-text-large-bold);
  color: var(--color-text-heading);
  text-wrap: nowrap;
  overflow: hidden;
  text-overflow: ellipsis;
}